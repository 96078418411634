import { combineReducers } from "redux";

import { toasterReducer } from "./redux/toaster/reducer";
import { userReducer } from "./redux/users/reducer";
import { tickerReducer } from "./redux/tickers/reducer";
import { clientReducer } from "./redux/clients/reducer";
import { bankAccountsReducer } from "./redux/bankAccounts/reducer";
import { authReducer } from "./redux/auth/reducer";
import { imageReducer } from "./redux/images/reducer";
import { commissionReducer } from "./redux/commissions/reducer";
import { brokerFeesReducer } from "./redux/brokerFees/reducer";
import { orderReducer } from "./redux/orders/reducer";
import { tickerASReducer } from "./redux/tickersAssetService/reducer";
import { commentsASReducer } from "./redux/pamComments/reducer";
import { feesReducer } from "./redux/fees/reducer";
import { ordersSummaryReducer } from "./redux/ordersSummary/reducer";
import { roboserverOrdersReducer } from "./redux/roboServerOrders/reducer";
import { rebalancingRequestsReducer } from "./redux/rebalancingRequests/reducer";
import { templatesReducer } from "./redux/templates/reducer";
import { mailsReducer } from "./redux/mails/reducer";
import { statementsReducer } from "./redux/accountStatements/reducer";
import { sentimentReducer } from "./redux/sentiments/reducer";
import { themeReducer } from "./redux/theme/reducer";
import { basketASReducer } from "./redux/basketSettingService/reducer";
import { solutionASReducer } from "./redux/solutionSettingService/reducer";
import { strategieASReducer } from "./redux/strategieSettingService/reducer";
import { parametersASReducer } from "./redux/parameterSettingService/reducer";
import { riskProfileASReducer } from "redux/riskProfileSettingService/reducer";
import { backtestASReducer } from "./redux/backtest/reducer";
import { groupASReducer } from "./redux/pamGroup/reducer";
import { newsASReducer } from "./redux/newsSentiments/reducer";
import { connectionsReducer } from "./redux/connectionLog/reducer";
import userRolesReducer from "./redux/userRoles/reducer";
import { strategySettingServiceApi } from "./redux/strategieSettingService/api";
import { configureStore } from "@reduxjs/toolkit";
import { tickersApi } from "redux/tickers/api";
import { BasketApi } from "redux/basketSettingService/api";
import tickersReducer from "redux/tickers/tickerSlice";
import basketItemsReducer from "redux/basketSettingService/BasketSlice";
import basketEditItemsReducer from "redux/basketSettingService/editBasketSlice";
import recommendationReducer from "redux/recommendations/recommendationSlices";
import { SolutionApi } from "redux/solutionSettingService/api";
import { usersApi } from "redux/users/apiv2";
import { currentUserApi } from "redux/auth/api";
import { rolesApi } from "redux/userRoles/api";
import { templatesApi } from "redux/templates/api";
import { PamApi } from "redux/pam/api";
import { groupsApi } from "redux/pam/groupsApi";
import { recommendationApi } from "redux/recommendations/api";
import { smartfolioApi } from "redux/smartfolios/api";
import { InvestmentsApi } from "redux/Investments/api";
import { SplitApiV2 } from "redux/apiv2";
import { SplitApi } from "redux/api";
import { dividendApi } from "redux/dividend/api";
import { dividendFormSlice } from "redux/dividend/dividendFormSlice";
import { dividendRebalancingApi } from "redux/dividendRebalancing/api";
import { reportSummariesApi } from "redux/reportSummaries/api";
import { clientsApi } from "redux/clients/apiv2";
import { OrdersApi } from "redux/orders/api";
import { OrdersSummary } from "redux/ordersSummary/api";
import tableFilterSlice from './redux/Investments/TableFilters';

const allReducers = combineReducers({
  toaster: toasterReducer,
  ticker: tickerReducer,
  user: userReducer,
  dividend: dividendFormSlice.reducer,
  client: clientReducer,
  orders: orderReducer,
  auth: authReducer,
  image: imageReducer,
  themeAS: themeReducer,
  commission: commissionReducer,
  brokerFees: brokerFeesReducer,
  tickerAS: tickerASReducer,
  commentAs: commentsASReducer,
  fees: feesReducer,
  ordersSummary: ordersSummaryReducer,
  bankAccount: bankAccountsReducer,
  roboserverOrders: roboserverOrdersReducer,
  rebalancing: rebalancingRequestsReducer,
  templates: templatesReducer,
  mails: mailsReducer,
  statements: statementsReducer,
  sentiments: sentimentReducer,
  basketAS: basketASReducer,
  solutionAS: solutionASReducer,
  strategieAS: strategieASReducer,
  riskProfileAS: riskProfileASReducer,
  parametersAS: parametersASReducer,
  backtestAS: backtestASReducer,
  groupAS: groupASReducer,
  newsAS: newsASReducer,
  userRoles: userRolesReducer,
  connectionAS: connectionsReducer,
  tickers: tickersReducer,
  basketItems: basketItemsReducer,
  basketEditItems: basketEditItemsReducer,
  tableFilter: tableFilterSlice,
  // strategieA: strategiesReducer,
  recommendation: recommendationReducer,
  [SplitApi.reducerPath]: SplitApi.reducer,
  [SplitApiV2.reducerPath]: SplitApiV2.reducer
});

export const store = configureStore({
  reducer: allReducers,
  middleware: (getDefaultMiddleware) => {
    const defaultMiddleware = getDefaultMiddleware();
    return [
      ...defaultMiddleware,
      strategySettingServiceApi.middleware,
      tickersApi.middleware,
      usersApi.middleware,
      currentUserApi.middleware,
      rolesApi.middleware,
      templatesApi.middleware,
      recommendationApi.middleware,
      smartfolioApi.middleware,
      InvestmentsApi.middleware,
      BasketApi.middleware,
      SolutionApi.middleware,
      PamApi.middleware,
      groupsApi.middleware,
      dividendApi.middleware,
      dividendRebalancingApi.middleware,
      reportSummariesApi.middleware,
      clientsApi.middleware,
      OrdersApi.middleware,
      OrdersSummary.middleware
    ] as ReturnType<typeof getDefaultMiddleware>;
  }
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
